import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';


export const routes: Routes = [
    // { path: 'ejecutar/:id', component: EjecutarModeloComponent },
    // { path: 'creacion', component: CreacionModeloComponent },
    { 
        path: '', 
        component: HomeComponent,
        pathMatch: 'full'
    },
    { 
        path: 'auth',
        loadComponent: () => import('./auth/login.component').then(m=>m.LoginComponent)
    },
    { 
        path: 'pages',
        loadChildren: () => import('./pages/pages.routes').then(m=>m.PAGE_ROUTES)
    },
    {
        path: '**',
        redirectTo: ''
    }
];
