<nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Cementos Pacasmayo</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" aria-expanded="false">Image Recognition</a>
            <ul class="dropdown-menu">
              <li>
                <a [routerLink]="'/pages/image-recognition/creacion-modelo'" routerLinkActive="active" ariaCurrentWhenActive="page" class="dropdown-item">Creación de modelo</a>
              </li>
              <li class="dropdown">
                <!-- <div> -->
                <a class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" (click)="$event.stopPropagation()" href="#" (click)="getModelos()">Seleccionar modelo</a>
                <ul class="dropdown-menu">
                  <li *ngFor="let modelo of modelos" >        
                    <a [routerLink]="'/pages/image-recognition/ejecutar-modelo/'+modelo.nombre.split('.')[0]" routerLinkActive="active" ariaCurrentWhenActive="page" class="dropdown-item">{{modelo.nombre}}</a>
                  </li>
                </ul>  
              <!-- </div> -->
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a [routerLink]="'/pages/object-detection'" class="nav-link active" aria-current="page" href="#">Object detection</a>
          </li>
        </ul>
      </div>
    </div>
</nav>
