import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';
import { CreacionModeloComponent } from './pages/image-recognition/creacion-modelo/creacion-modelo.component';
import { CommonModule } from '@angular/common';
import { EjecutarModeloComponent } from './pages/image-recognition/ejecutar-modelo/ejecutar-modelo.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, CreacionModeloComponent, CommonModule, EjecutarModeloComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'frontend-clasificadorImagenes';
  constructor() {
    console.log('Inició correctamente');
  }
}
