import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { authGuard } from './guards/auth.guard';
import { LoginComponent } from './auth/login.component';


export const routes: Routes = [
    // { 
    //     path: '', 
    //     component: HomeComponent,
    //     pathMatch: 'full',
    //     canActivate: [authGuard]  // Protege el acceso al home
    // },
    { 
        path: '',
        redirectTo: 'pages',  // Redirige a 'pages' si esta es la ruta que deseas para la raíz
        pathMatch: 'full'
    },
    { 
        path: 'auth',
        // component: LoginComponent,
        loadComponent: () => import('./auth/login.component').then(m=>m.LoginComponent)
    },
    { 
        path: 'pages',
        loadChildren: () => import('./pages/pages.routes').then(m=>m.PAGE_ROUTES),
        canActivate: [authGuard]
    },
    {
        path: '**',
        redirectTo: 'auth'
    }
];