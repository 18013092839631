import { CanActivateFn, Router, UrlTree  } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const authGuard: CanActivateFn = (): Observable<boolean | UrlTree> =>{
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isLoggedIn$ // Usamos el observable
    .pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
          return true; // Permite el acceso a la ruta /pages si está autenticado
        } else {
          return router.createUrlTree(['/auth']); // Redirige a /auth si no está autenticado
        }
      })
    );
};
